import React from 'react'

export default function ZomatoSwiggy() {
  return (
    <div className='fixed bottom-10 shadow-xl rounded-lg right-5  bg-white p-3'>
        <p className='text-center  animate-bounce'>Order Now!!!</p>
        <a href="http://www.zomato.com/ncr/the-cage-cafe-krishna-nagar-new-delhi" className='text-white inline-block p-2 pl-4 rounded-l-full bg-[#cb202d]' target="_blank" rel="noopener noreferrer">Zomato</a>
        <a href="https://www.swiggy.com/restaurants/the-cage-cafe-f-blolck-krishna-nagar-delhi-15236" className='inline-block p-2 text-white pr-4 rounded-r-full bg-[#fc8019]' target="_blank" rel="noopener noreferrer">Swiggy</a>
    </div>
  )
}
