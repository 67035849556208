import React, { useState } from 'react'
import '../App.css'
import { Link } from 'react-router-dom'

export default function Navbar() {
  const [state, setState ] = useState(0)
  const [button, setButton] = useState("menu")
  const toggleNav =()=>{
      if(state ===0){
        setState(1)
        setButton("close")
        console.log(state)
      }
      else{
        setState(0)
        setButton("menu")
        console.log(state)
      }
    }
    const backNav = ()=>{
    setButton("menu")
    setState(0)
  }
  return (
    <nav className='' style={{zIndex: 3, position:'fixed', width: '100vw', boxShadow: '2px 2px 16px rgb(0 0 0 / 48%)'}}>
      <div className=" bg-[#FFEFB2] ">
        <div className='flex justify-between px-5 lg:px-10 py-2 bg-[#FFEFB2] max-w-[1200px] mx-auto'>

        <Link to={'/'}><img src="https://i.ibb.co/0YH0wnh/293041660-538568821300299-8272804081369724134-n.jpg"  alt="logo  for absolute" className=' hover:scale-125 duration-200  absolute top-2 rounded-full h-24  border-2'/></Link>
        <ul className={state === 1? "z-10 text-right absolute  lg:static top-28 bg-[#682900] duration-300 text-white lg:w-auto w-screen lg:p-0 left-0 p-6 z-0 lg:h-auto h-screen lg:flex " : " gap-10  absolute lg:static top-28 duration-300 lg:w-auto w-screen lg:p-0 left-full p-6 z-0 lg:h-auto h-screen lg:flex"}>
        {/* <ul className='flex gap-16 absolute left-2'> */}
          {/* className={state === 1? "z-10 absolute lg:static top-14 duration-300 bg-white lg:w-auto w-screen lg:p-0 left-0 p-6 z-0 lg:h-auto h-screen lg:flex" : "  absolute lg:static top-14 duration-300 lg:w-auto w-screen lg:p-0 left-full p-6 z-0 lg:h-auto h-screen lg:flex"} */}
          <Link onClick={backNav} className='p-3 block' to={'/'}>Home</Link>
          <Link onClick={backNav} className='p-3 block' to={'/menu'}>Menu</Link>
          <Link onClick={backNav} className='p-3 block' to={'/gallery'}>Reviews & Gallery</Link>
          <Link onClick={backNav} className='p-3 block' to={'/about'}>About & Contact</Link>
        </ul>
        <span onClick={toggleNav} className="material-symbols-outlined cursor-pointer p-3 lg:hidden" >{button}</span>
        {/* <p className='p-3 lg:hidden' onClick={toggleNav}>x</p> */}
        </div>
      </div>
      <div className='bg-[#682900]'>

      <p className='text-right px-10 py-3 text-white bg-[#682900] max-w-[1200px] mx-auto italic' style={{fontFamily: 'Prompt'}}>Jail You Never Wanna Escape</p>
      </div>
    </nav>
  )
}
