export const Data = [
    {
        id : 1,
        name : 'MANCHURIAN DRY (10PCS)',
        category : 'food',
        price : 175,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MANCHURIAN GRAVY (10PCS)',
        category : 'food',
        price : 195,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI PANEER DRY (12PCS)',
        category : 'food',
        price : 200,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI PANEER GRAVY (12PCS)',
        category : 'food',
        price : 220,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI GOBHI DRY (10PCS)',
        category : 'food',
        price : 180,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI GOBHI GRAVY (10PCS)',
        category : 'food',
        price : 200,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'HONEY CHILLI GOBHI DRY (10PCS)',
        category : 'food',
        price : 195,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'HONEY CHILLI GOBHI GRAVY (10PCS)',
        category : 'food',
        price : 215,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI POTATO',
        category : 'food',
        price : 155,
        price2 : null,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'HONEY CHILLI POTATO',
        category : 'food',
        price : 170,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI CHAAP DRY (10PCS)',
        category : 'food',
        price : 175,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI CHAAP GRAVY (10PCS)',
        category : 'food',
        price : 195,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI MUSHROOM DRY (10PCS)',
        category : 'food',
        price : 195,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI MUSHROOM GRAVY (10PCS)',
        category : 'food',
        price : 215,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'SPRING ROLL',
        category : 'food',
        price : 140,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER CHEESE MANCHURIAN DRY (8PCS)',
        category : 'food',
        price : 200,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER CHEESE MANCHURIAN GRAVY (8PCS)',
        category : 'food',
        price : 220,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'DAHI KE SHOLEY (4PCS)',
        category : 'food',
        price : 195,
        price2 : null,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VEG. CHOWMEIN',
        category : 'food',
        price : 145,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VEG. BUTTER CHOWMEIN',
        category : 'food',
        price : 155,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI GARLIC CHOWMEIN',
        category : 'food',
        price : 160,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER CHOWMEIN',
        category : 'food',
        price : 170,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MUSHROOM CHOWMEIN',
        category : 'food',
        price : 170,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER MUSHROOM CHOWMEIN',
        category : 'food',
        price : 190,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'BUTTER PANEER CHOWMEIN',
        category : 'food',
        price : 180,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'SINGAPURI CHOWMEIN',
        category : 'food',
        price : 185,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'HAKKA NOODLES',
        category : 'food',
        price : 185,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VEG. CHOPSUEY',
        category : 'food',
        price : 175,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHINESE CHOPSUEY',
        category : 'food',
        price : 195,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER CHOPSUEY',
        category : 'food',
        price : 215,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CAGE CAFE SPL. CHOWMEIN',
        category : 'food',
        price : 205,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'WHITE SAUCE PASTA',
        category : 'food',
        price : 180,
        price2 : null,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'RED SAUCE PASTA',
        category : 'food',
        price : 180,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MIX SAUCE PASTA',
        category : 'food',
        price : 185,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER PASTA',
        category : 'food',
        price : 195,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'BAKED PASTA',
        category : 'food',
        price : 200,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER BAKED PASTA',
        category : 'food',
        price : 210,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MUSHROOM PASTA',
        category : 'food',
        price : 190,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CAGE CAFE SPL. PASTA',
        category : 'food',
        price : 220,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'NACHOS BAKED',
        category : 'food',
        price : 170,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'NACHOS CHAAT',
        category : 'food',
        price : 185,
        price2 : null,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHEESE PIZZA',
        category : 'food',
        price : 90,
        price2 : 160,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'SINGLE TOPPING PIZZA',
        category : 'food',
        price : 105,
        price2 : 175,
        bst : 1,
        extra : '(ONION, CAPSICUM, TOMATO, CORN)' ,
    },
    {
        id : 1,
        name : 'DOUBLE TOPPING PIZZA',
        category : 'food',
        price : 115,
        price2 : 180,
        bst : 1,
        extra : '(ONION, CAPSICUM, TOMATO, MUSHROOM, CORN)' ,
    },
    {
        id : 1,
        name : 'VEGGIE LOVER PIZZA',
        category : 'food',
        price : 130,
        price2 : 195,
        bst : 1,
        extra : '(ONION, CAPSICUM, TOMATO)' ,
    },
    {
        id : 1,
        name : 'CHILLI PANEER PIZZA',
        category : 'food',
        price : 140,
        price2 : 200,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI MUSHROOM PIZZA',
        category : 'food',
        price : 140,
        price2 : 200,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER PIZZA',
        category : 'food',
        price : 130,
        price2 : 195,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'FARM HOUSE PIZZA',
        category : 'food',
        price : 140,
        price2 : 215,
        bst : 1,
        extra : '(ONION, CAPSICUM, TOMATO, MUSHROOM, PANEER, CORN)',
    },
    {
        id : 1,
        name : 'CAGE CAFE SPL. PIZZA',
        category : 'food',
        price : 155,
        price2 : 240,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'EXTRA NORMAL CHEESE',
        category : 'food',
        price : 25,
        price2 : 40,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'EXTRA MOZZARELLA CHEESE',
        category : 'food',
        price : 35,
        price2 : 55,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VEG. BURGER',
        category : 'food',
        price : 70,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER BURGER',
        category : 'food',
        price : 90,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER CHEESE BURGER',
        category : 'food',
        price : 105,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHEESE BURGER',
        category : 'food',
        price : 85,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHOWMEIN BURGER',
        category : 'food',
        price : 90,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI PANEER BURGER',
        category : 'food',
        price : 100,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CAGE CAFE SPL. BURGER',
        category : 'food',
        price : 120,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    // {
    //     id : 1,
    //bst : 0,
    //     name : 'EXTRA CHEESE',
    //     category : '',
    //     price : 30,
    //     price2 : null,
    //bst : 0,
    //     extra : '' ,
    // },
    {
        id : 1,
        name : 'TOMATO SOUP',
        category : 'drink',
        price : 120,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MANCHOW SOUP',
        category : 'drink',
        price : 130,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'HOT N SOUR SOUP',
        category : 'drink',
        price : 130,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'TALUMEIN SOUP',
        category : 'drink',
        price : 130,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'SWEET CORN SOUP',
        category : 'drink',
        price : 130,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'NOODLES SOUP',
        category : 'drink',
        price : 140,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MIX. VEG. SOUP',
        category : 'drink',
        price : 140,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MIX. VEG. TOMATO SOUP',
        category : 'drink',
        price : 140,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'ONION CHEESE KULCHA',
        category : 'food',
        price : 105,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CAPSICUM CHEESE KULCHA',
        category : 'food',
        price : 105,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VEG. CHEESE KULCHA',
        category : 'food',
        price : 110,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI MUSHROOM KULCHA',
        category : 'food',
        price : 120,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI PANEER KULCHA',
        category : 'food',
        price : 120,
        price2 : null,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CAGE CAFE SPL. KULCHA',
        category : 'food',
        price : 135,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MALAI CHAAP',
        category : 'food',
        price : 195,
        price2 : null,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER TIKKA',
        category : 'food',
        price : 205,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MUSHROOM TIKKA',
        category : 'food',
        price : 210,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER ACHARI TIKKA',
        category : 'food',
        price : 220,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'STUFF CHAAP',
        category : 'food',
        price : 235,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MALAI PANEER TIKKA',
        category : 'food',
        price : 230,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'ACHARI CHAAP',
        category : 'food',
        price : 210,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'LEMON CHAAP',
        category : 'food',
        price : 205,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MASALA CHAAP',
        category : 'food',
        price : 210,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VEG. SEEKH KABAB',
        category : 'food',
        price : 215,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'TANDOORI ALOO',
        category : 'food',
        price : 225,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'FRENCH FRIES',
        category : 'food',
        price : 85,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MASALA FRIES',
        category : 'food',
        price : 95,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PERI PERI FRIES',
        category : 'food',
        price : 105,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'GARLIC BREAD CHEESE ',
        category : 'food',
        price : 120,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'GARLIC BREAD SPICY ',
        category : 'food',
        price : 130,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'EXOTICA GARLIC BREAD ',
        category : 'food',
        price : 140,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHEESE DIP',
        category : 'food',
        price : 30,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VEG. CHEESE GRILLED ',
        category : 'food',
        price : 120,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER ONION GRILLED ',
        category : 'food',
        price : 135,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CAPSICUM ONION GRILLED ',
        category : 'food',
        price : 115,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI MUSHROOM GRILLED ',
        category : 'food',
        price : 125,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI PANEER GRILLED ',
        category : 'food',
        price : 130,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VEG. CLUB GRILLED ',
        category : 'food',
        price : 125,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PIZZA SANDWICH GRILLED ',
        category : 'food',
        price : 130,
        price2 : null,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CORN CHEESE SANDWICH ',
        category : 'food',
        price : 130,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CAGE CAFE SPL. SANDWICH GRILLED ',
        category : 'food',
        price : 140,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'DAL FRY',
        category : 'food',
        price : 195,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'BUTTER DAL FRY ',
        category : 'food',
        price : 205,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'DAL TADKA ',
        category : 'food',
        price : 205,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'DAL MAKHANI ',
        category : 'food',
        price : 220,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'DAL HANDI ',
        category : 'food',
        price : 235,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'SPL. DAL MAKHANI ',
        category : 'food',
        price : 240,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'AALO GOBHI ',
        category : 'food',
        price : 185,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'AALO JEERA ',
        category : 'food',
        price : 185,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'DUM ALOO ',
        category : 'food',
        price : 230,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MIX VEG. ',
        category : 'food',
        price : 225,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHANA MASALA ',
        category : 'food',
        price : 225,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PALAK PANEER ',
        category : 'food',
        price : 225,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'SHAHI PANEER ',
        category : 'food',
        price : 230,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'TAWA PANEER ',
        category : 'food',
        price : 240,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER DO PAYAZA ',
        category : 'food',
        price : 245,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER LABABDAR ',
        category : 'food',
        price : 245,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MATAR PANEER ',
        category : 'food',
        price : 245,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'KADHAI PANEER ',
        category : 'food',
        price : 250,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER BUTTER MASALA ',
        category : 'food',
        price : 255,
        price2 : null,
        bst : 1,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER PASANDA ',
        category : 'food',
        price : 255,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER BHURJI ',
        category : 'food',
        price : 270,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'SHAHI CHAAP ',
        category : 'food',
        price : 230,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'TAWA CHAAP ',
        category : 'food',
        price : 235,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'KADHAI CHAAP ',
        category : 'food',
        price : 240,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MUSHROOM MASALA ',
        category : 'food',
        price : 230,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'KADHAI MUSHROOM ',
        category : 'food',
        price : 240,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MATAR MUSHROOM ',
        category : 'food',
        price : 245,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'NAVRATAN KORMA ',
        category : 'food',
        price : 255,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER TAKATAK ',
        category : 'food',
        price : 255,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'SPL. PANEER TIKKA MASALA ',
        category : 'food',
        price : 275,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VEG. FRIED RICE ',
        category : 'food',
        price : 155,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER FRIED RICE ',
        category : 'food',
        price : 175,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MUSHROOM FRIED RICE ',
        category : 'food',
        price : 175,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHILLI GARLIC FRIED RICE ',
        category : 'food',
        price : 165,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'SINGAPURI FRIED RICE ',
        category : 'food',
        price : 195,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MIX VEG. FRIED RICE ',
        category : 'food',
        price : 195,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CAGE CAFE SPL. FRIED RICE ',
        category : 'food',
        price : 205,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PLAIN RICE ',
        category : 'food',
        price : 120,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'JEERA RICE ',
        category : 'food',
        price : 140,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MATAR RICE ',
        category : 'food',
        price : 155,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PANEER RICE ',
        category : 'food',
        price : 175,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VEG. PULAO ',
        category : 'food',
        price : 175,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VEG. BIRYANI ',
        category : 'food',
        price : 185,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'ROTI',
        category : 'food',
        price : 15,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'BUTTER ROTI',
        category : 'food',
        price : 20,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'RUMALI ROTI',
        category : 'food',
        price : 15,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'NAAN',
        category : 'food',
        price : 37,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'BUTTER NAAN',
        category : 'food',
        price : 42,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'ONION KULCHA',
        category : 'food',
        price : 50,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'GARLIC NAAN',
        category : 'food',
        price : 50,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'STUFF NAAN',
        category : 'food',
        price : 60,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'STUFF PANEER NAAN',
        category : 'food',
        price : 75,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'LACHAA PARANTHA',
        category : 'food',
        price : 40,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MISSI ROTI',
        category : 'food',
        price : 35,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MISSI ROTI WITH ONION',
        category : 'food',
        price : 40,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'STUFF PARANTHA',
        category : 'food',
        price : 55,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'STUFF PANEER PARANTHA',
        category : 'food',
        price : 70,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'HARI MIRCH PARANTHA',
        category : 'food',
        price : 50,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'LAL MIRCH PARANTHA',
        category : 'food',
        price : 50,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'JEERA NAAN',
        category : 'food',
        price : 50,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PUDINA NAAN',
        category : 'food',
        price : 55,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PUDINA PARANTHA',
        category : 'food',
        price : 45,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHEESE NAAN ',
        category : 'food',
        price : 110,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'KASHMIRI NAAN ',
        category : 'food',
        price : 100,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'ONION SALAD',
        category : 'food',
        price : 80,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'GREEN SALAD ',
        category : 'food',
        price : 100,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CURD',
        category : 'food',
        price : 70,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PLAIN RAITA ',
        category : 'food',
        price : 100,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'BOONDI RAITA ',
        category : 'food',
        price : 110,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MIX RAITA ',
        category : 'food',
        price : 120,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'PINEAPPLE RAITA ',
        category : 'food',
        price : 130,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'ROASTED PAPAD (1 PCS)',
        category : 'food',
        price : 15,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MASALA PAPAD (1 PCS)',
        category : 'food',
        price : 35,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VANILLA ICE CREAM',
        category : 'food',
        price : 70,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'STRAWBERRY ICE CREAM',
        category : 'food',
        price : 70,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'TWO IN ONE ICE CREAM',
        category : 'food',
        price : 75,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHOCOLATE ICE CREAM',
        category : 'food',
        price : 80,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'BUTTER SCOTCH ICE CREAM',
        category : 'food',
        price : 80,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VANILLA SHAKE ',
        category : 'drink',
        price : 115,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'STRAWBERRY SHAKE ',
        category : 'drink',
        price : 115,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'CHOCOLATE SHAKE ',
        category : 'drink',
        price : 125,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'BUTTER SCOTCH SHAKE ',
        category : 'drink',
        price : 125,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'OREO SHAKE ',
        category : 'drink',
        price : 150,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'KIT KAT SHAKE ',
        category : 'drink',
        price : 150,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'FERRERO ROCHER SHAKE ',
        category : 'drink',
        price : 185,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'ICE TEA ',
        category : 'drink',
        price : 100,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MASALA LEMONADE ',
        category : 'drink',
        price : 105,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'SOFT DRINK',
        category : 'drink',
        price : 40,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'VIRGIN MOJITO ',
        category : 'drink',
        price : 125,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'GREEN APPLE MOJITO ',
        category : 'drink',
        price : 145,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'WATERMELON MOUIITO ',
        category : 'drink',
        price : 145,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'COLD COFFEE ',
        category : 'drink',
        price : 105,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'COLD COFFEE WITH ICE CREAM',
        category : 'drink',
        price : 125,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MALAI KOFTA (MIX GRAVY) ',
        category : 'food',
        price : 145,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    {
        id : 1,
        name : 'MALAI KOFTA (WHITE GRAVY) ',
        category : 'food',
        price : 145,
        price2 : null,
        bst : 0,
        extra : '' ,
    },
    
]