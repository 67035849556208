import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import Home from './Components/Home'
import About from './Components/About'
import './App.css'
import Menu from './Components/Menu'
import Gallery from './Components/Gallery'
import ScrollToTop from './Components/ScrollToTop'
import ZomatoSwiggy from './Components/ZomatoSwiggy'
import Call from './Components/Call'
import MenuProvider from './Components/MenuContext'


export default function App() {

  return (
    <BrowserRouter>
    <ScrollToTop/>
   
    <Navbar/>
    <MenuProvider>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/menu' element={<Menu/>} />
      <Route path = '/gallery' element={<Gallery/>} />
      <Route path='/about' element={<About/>} />
    </Routes>
    </MenuProvider>
    <ZomatoSwiggy/>
    <Call/>

    <Footer/>
    </BrowserRouter>
  )
}
