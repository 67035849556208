import React from 'react'
import emailjs from '@emailjs/browser';

import { useRef } from 'react';

export default function About() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_us3zl6j', 'template_uw9csxi', form.current, '_95vvdCFeJuKIe-58')
      .then((result) => {
          console.log(result.text);
          alert("email sent!");
        }, (error) => {
          console.log(error.text);
          alert("Error in sending!");
      });
  };
  return (
    <div className='max-w-[960px] pt-28  px-6 lg:px-0  mx-auto'>
        <h2 className='medium mt-5'>Our Vision</h2>
        <p className='mb-7'>Our vision is to create an unforgettable dining experience that transcends the ordinary. I aspire to captivate guests with a blend of exceptional cuisine and the immersive ambiance of our jail-themed setting. With a commitment to culinary excellence and a passion for delivering unparalleled moments, I envision the Cage Cafe becoming a destination where patrons savor not just the food, but an extraordinary escape from the mundane.</p>
        {/* <h2 className='medium mt-5'>Our Vision</h2> */}
        <p className='mb-7 medium yellow p-10 rounded-lg shadow-md'>Step into the captivating allure of the Cage Cafe, where a jail-themed ambiance transforms dining into an immersive adventure.</p>
        {/* <h2 className='medium mt-5'>Our Vision</h2>
        <p className='mb-7'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit dolorem architecto libero neque ullam odio laborum sapiente quam voluptas accusantium culpa et, sit, rem molestias iste enim ipsam. Corrupti exercitationem sit officiis laudantium doloribus provident dolor numquam! Vero, fuga minus?</p> */}
        <hr />
        <div className="grid grid-cols-1 gap-10 my-7 lg:grid-cols-3 align-start">
            <div className="address">
                <p className='medium'>Address</p>
                <p>Outlet 1 : F-2/8A KRISHNA NAGAR Delhi, India 110051</p>
                <p>Outlet 2 : Plot No-3, Dayanand Vihar, Anand Vihar, Delhi, 110051</p>
                {/* <p>address</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, quia.</p> */}
                <p className='medium mt-5'>Phone</p>
                <p>+91 98181 80106</p>
            </div>
            {/* <hr className='rotate-90  h-200 w-40 bg-red-400 '/> */}
            {/* <div className="grid-col-span-2"></div> */}
            <form  ref={form} onSubmit={sendEmail} className='grid grid-cols-1  '>
              {/* <label>Name</label> */}
              <input type="text" required name="from_name" placeholder='Name' className='p-2 py-1 text-sm lg:text-md border-2 border-slate-400 rounded-md mb-2' />
              {/* <label>Email</label> */}
              <input type="tel" pattern='[0-9]{10}' required name="from_number" placeholder='Phone No.' className='p-2 py-1 text-sm lg:text-md border-2 border-slate-400 rounded-md  mb-2' />
              <input type="email" required name="from_email" placeholder='Email' className='p-2 py-1 text-sm lg:text-md border-2 border-slate-400 rounded-md  mb-2' />
              {/* <label>Message</label> */}
              <textarea name="message" placeholder='Message' required rows={3} className='p-2 py-1 text-sm lg:text-md border-2 border-slate-400 rounded-md  mb-4' />
              <input type="submit" value="Send" className='cursor-pointer  mx-12 p-3 active:ring-2 active:ring-offset-2 duration-200 active:ring-orange-800 rounded-sm text-white bg-[#682900] text-2xl '/>
            </form>
            <div className="3rd">
              <h2 className='medium'>Book Appointment</h2>
              <p>For Franchise? Call Us</p>
                <p className='text-lg text-red-800 font-medium'>+91 9911667113</p>
                <p className='text-lg text-red-800 font-medium'>+91 9015449868</p>
                
              <p>Fill the Form for Exclusive Business Bookings Beyond Culinary Delights</p>
              {/* <p>Fill this form</p> */}
            </div>

        </div>
    </div>
  )
}
