import React from 'react'

export default function Gallery() {
  return (
    <div className='max-w-[960px] pt-28  px-6 lg:px-0  mx-auto'>
        <div className="reviews my-10">
            <h2 className='medium'>Reviews</h2>
            <div className="grid grid-cols-1 gap-10 lg:grid-cols-3 items-start">
                <div className='bg-slate hover:shadow-2xl shadow-lg duration-200 -200 rounded-xl px-5 py-3'>
                    <p className='mb-2'>⭐⭐⭐⭐⭐</p>
                    <p>It was a great experience. the prices are very Pocket friendly and the taste was delicious.I love paneer Tikka specially. Vegetarian options: Paneer Tikka.</p>
                    <span className='smallest'>Bhagyashri Bhandari</span>
                </div>
                <div className='bg-slate hover:shadow-2xl shadow-lg duration-200 -200 rounded-xl px-5 py-3'>
                    <p className='mb-2'>⭐⭐⭐⭐</p>
                    <p>This place has a nice ambience of jail theme. Neat and clean place. Quality and quantity of food was good. Food tastes delicious. Budget friendly. Overall good experience.</p>
                    <span className='smallest'>Sandeep Pal</span>
                </div>
                <div className='bg-slate hover:shadow-2xl shadow-lg duration-200 -200 rounded-xl px-5 py-3'>
                    <p className='mb-2'>⭐⭐⭐⭐⭐</p>
                    <p>Loved this place very much.. ambience and theme of jail is really cool!!..and best part is their food Everything is good, tasty and budget friendly!!</p>
                    <span className='smallest'>Garvita Kapoor</span>
                </div>
            </div>
        </div>
        

        <div className="gallery">
            <h2 className='medium'>Gallery</h2>
            <div className="grid grid-cols-1 gap-10 justify-center lg:grid-cols-3">
                <div className="part1">
                    <img src="https://i.ibb.co/1TB7jpY/9c.jpg" alt="img" className='w-full  rounded-lg my-5'/>
                    <img src="https://i.ibb.co/J2VbFcB/8c.jpg" alt="img" className='w-full  rounded-lg my-5'/>
                    <img src="https://i.ibb.co/cF7HpGy/7c.jpg" alt="img" className='w-full  rounded-lg my-5'/>
                    <img src="https://i.ibb.co/W0n5vFM/5c.jpg" alt="img" className='w-full  rounded-lg my-5'/>
                </div>
                <div className="part2">
                    <img src="https://i.ibb.co/nQqTksK/3c.jpg" alt="img" className='w-full  rounded-lg my-5'/>
                    <img src="https://i.ibb.co/ZY3NxtH/2c.jpg" alt="img" className='w-full  rounded-lg my-5'/>
                    <img src="https://i.ibb.co/y46LrzX/1c.jpg" alt="img" className='w-full  rounded-lg my-5'/>
                    
                </div>
                <div className="part3">
                    <img src="https://i.ibb.co/KKxfXMp/10c.jpg" alt="img" className='w-full  rounded-lg my-5'/>
                    <img src="https://i.ibb.co/4tLksd7/6c.jpg" alt="img" className='w-full  rounded-lg my-5'/>
                    <img src="https://i.ibb.co/DM0hfKX/4c.jpg" alt="img" className='w-full  rounded-lg my-5'/>
                  
                    
                </div>
            </div>
        </div>
    </div>
  )
}
