import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { MenuContext } from './MenuContext'

export default function Home() {
    const { changeCategory} = useContext(MenuContext);
  return (
    <div className='max-w-[960px] mx-auto pt-28 px-6 lg:px-0 '>
        {/* <p className="text-[9.8px]">This is a text</p>
        <p className="text-[41.88px] text-slate-800">This is a text</p>
        <p className="text-[25.8px]">This is a text</p>
        <p className="text-[16px]">This is a text</p> */}
        <div className='my-10'>
            <h1 className="font medium ">Designed For You</h1>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-10 mt-5 mx-4 md:mx-0 justify-items-center">
                <Link to={'/menu'} className='text-center hover:scale-105 duration-200' onClick={()=>{changeCategory('bestseller');}}>
                    <img src="https://www.vegrecipesofindia.com/wp-content/uploads/2021/06/fettuccine-alfredo-1.jpg" alt="img"  className='h-24 shadow-lg hover:shadow-xl duration-200  lg:h-36 rounded-full'/>
                    <p>BestSellers</p>
                    
                </Link>
                <Link to={'/menu'} className='text-center hover:scale-105 duration-200'  onClick={()=>{changeCategory('food');}}>
                    <img src="https://preppykitchen.com/wp-content/uploads/2021/10/Cheese-Pizza-Recipe-Card-500x500.jpg" alt="img"  className='h-24 shadow-lg hover:shadow-xl duration-200  lg:h-36 rounded-full'/>
                    <p>Foods</p>
                </Link>
                <Link to={'/menu'} className='text-center hover:scale-105 duration-200' onClick={()=>{changeCategory('drink');}}>
                    <img src="https://i.ibb.co/kKZ6zvq/New-Project-3.png" alt="img"  className='h-24 shadow-lg hover:shadow-xl duration-200   lg:h-36 rounded-full'/>
                    <p>Drinks</p>
                </Link>
                <Link to={'/menu'} className='text-center hover:scale-105 duration-200'  onClick={()=>{changeCategory('all');}}>
                    <img src="https://i.ibb.co/fSfs2tJ/Whats-App-Image-2024-01-08-at-11-19-52-b5ba6201.jpg" alt="img"  className='h-24 shadow-lg hover:shadow-xl duration-200  lg:h-36 rounded-full'/>
                    <p>All Items</p>
                </Link>
            </div>
            <div className="offer lg:flex gap-10 p-10 yellow rounded-xl my-10 shadow-md">
                <img src="https://i.ibb.co/0YH0wnh/293041660-538568821300299-8272804081369724134-n.jpg" alt="offer" className='h-24 mb-5 lg:m-0 lg:h-36 rounded-full shadow-md'/>
                <div className="details">
                    <h2 className='medium'>The Cage Cafe - Jail You Never Wanna Escape</h2>
                    <p>Step into the intriguing world of the Cage Cafe, where culinary delights are served within the unique ambiance of a jail-themed setting. Indulge in a diverse menu featuring a delicious array of dishes, from savory classics to sweet escapes. Unleash your taste buds while surrounded by the intriguing and immersive atmosphere of this one-of-a-kind dining experience.</p>
                    {/* <p className='font-medium'>price</p> */}
                    {/* <p className="text-right smallest opacity-75">date</p> */}
                </div>
            </div>
            <div className='px-12 py-3 relative -top-16 w-fit mx-auto rounded-lg bg-white text-center  shadow-lg'>
                <p>For Franchise? Call Us</p>
                <p className='text-lg text-red-800 font-medium'>+91 9911667113</p>
                <p className='text-lg text-red-800 font-medium'>+91 9015449868</p>
            </div>

            <div className="reviews my-10">
                <h2 className='medium'>Reviews</h2>
                <div className="grid grid-cols-1 gap-10 lg:grid-cols-3 items-start ">
                <div className='bg-slate hover:shadow-2xl shadow-lg duration-200 -200 rounded-xl px-5 py-3'>
                    <p className='mb-2'>⭐⭐⭐⭐⭐</p>
                    <p>It was a great experience. the prices are very Pocket friendly and the taste was delicious.I love paneer Tikka specially. Vegetarian options: Paneer Tikka.</p>
                    <span className='smallest'>Bhagyashri Bhandari</span>
                </div>
                <div className='bg-slate hover:shadow-2xl shadow-lg duration-200 -200 rounded-xl px-5 py-3'>
                    <p className='mb-2'>⭐⭐⭐⭐</p>
                    <p>This place has a nice ambience of jail theme. Neat and clean place. Quality and quantity of food was good. Food tastes delicious. Budget friendly. Overall good experience.</p>
                    <span className='smallest'>Sandeep Pal</span>
                </div>
                <div className='bg-slate hover:shadow-2xl shadow-lg duration-200 -200 rounded-xl px-5 py-3'>
                    <p className='mb-2'>⭐⭐⭐⭐⭐</p>
                    <p>Loved this place very much.. ambience and theme of jail is really cool!!..and best part is their food Everything is good, tasty and budget friendly!!</p>
                    <span className='smallest'>Garvita Kapoor</span>
                </div>
                </div>

                <div className="recommended my-10">
                    <h2 className='medium'>Recommended</h2>
                    <hr />
                    <div className="grid grid-cols-1 gap-10 lg:grid-cols-3 mt-5 items-start">
                        <div className='shadow-md rounded-2xl bg-contain'>
                            <div className='h-40  rounded-t-lg bg-contain'  style={{background : "url('https://img-global.cpcdn.com/recipes/42f714dd16581cd1/680x482cq70/nachos-chat-recipe-main-photo.jpg')", backgroundSize : 'cover'}}></div>
                            {/* <img src="https://img-global.cpcdn.com/recipes/42f714dd16581cd1/680x482cq70/nachos-chat-recipe-main-photo.jpg" className='h-44 mx-auto obj-contain' alt="" /> */}
                            <div className=' px-5 py-2 bg-white rounded-b-lg'>
                                <p className='font-semibold'>Nachos Chaat</p>
                                <p>Indulge in the delightful fusion of crispy nachos and vibrant Indian chaat flavors with our mouthwatering Nachos Chaat!</p>
                                <p className='font-medium'>₹185/-</p>
                            </div>
                        </div>
                        <div className='shadow-md rounded-2xl bg-contain'>
                            <div className='h-40  rounded-t-lg bg-cover bg-bottom'  style={{background : "url('https://www.archanaskitchen.com/images/archanaskitchen/1-Author/Neha_Mathur/Achari_Paneer_Tikka_Recipe_Party_Food_400.jpg')",  backgroundSize : 'cover' }}></div>
                            <div className=' px-5 py-2 bg-white rounded-b-lg'>
                                <p className='font-semibold'>Paneer Tikka Masala Flavor</p>
                                <p>Savor the rich and creamy goodness of succulent paneer tikka bathed in a luscious masala gravy for a taste that's pure indulgence.</p>
                                <p className='font-medium'>₹205/-</p>
                            </div>
                        </div>
                        <div className='shadow-md rounded-2xl bg-contain'>
                            <div className='h-40  rounded-t-lg bg-contain'  style={{background : "url('https://thewoods.net.in/wp-content/uploads/2021/02/afganimctikka.jpg')", backgroundSize : 'cover'}}></div>
                            <div className=' px-5 py-2 bg-white rounded-b-lg'>
                                <p className='font-semibold'>Malai Chaap</p>
                                <p>Experience the exquisite blend of tender soy chaap marinated in creamy malai, char-grilled to perfection, delivering a delectable treat for your taste buds.</p>
                                <p className='font-medium'>₹195/-</p>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
