import React, { createContext, useState } from 'react'
import { useEffect } from 'react';
// import { Data } from './Data'

export const MenuContext = createContext();

function MenuProvider(props) {
  

    const [category, setCategory] = useState('all')

    const changeCategory = (category)=>{
        setCategory(category);
        console.log(category)
    }
    useEffect(()=>{
        window.scrollTo(0,0);
    }, [category])
    const contextvalue = {category, setCategory, changeCategory}

    return (
    <MenuContext.Provider value={contextvalue}>{props.children}</MenuContext.Provider>
  )
}
export default MenuProvider;