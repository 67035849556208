import React from 'react'

export default function MenuItem(props) {
  return (<div className=' p-3 lg:p-5 duration-100 hover:shadow-lg rounded-lg border-2 my-1 lg:my-3'>
    <div className="inline-block w-5 h-5 border-2 border-green-700 flex justify-center items-center mb-2">
        <div className="inline-block w-3 h-3 rounded-lg bg-green-700"></div>
    </div>
    <div className=' flex  justify-between'>
        <p className='lg:text-lg'>{props.name}</p>
        <div className=' '>
            <span className='font-semibold'>{props.price}</span>
        <span className={props.price2 === null ? 'font-semibold' : 'font-semibold ml-4 lg:ml-8'}>{props.price2}</span>
        </div>
    </div>
        <p className="text-xs">{props.extra}</p>
  </div>
  )
}
