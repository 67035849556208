import React, { useContext } from 'react'
import { Data } from './Data'
import MenuItem from './MenuItem'
import { MenuContext } from './MenuContext'


function Menu() {
  const {category, changeCategory} = useContext(MenuContext);
  return (
    <div className='max-w-[960px] pt-28 px-6 lg:px-0 min-h-screen  mx-auto'>
      <ul className='sticky top-28 flex overflow-auto gap-4 lg:gap-10 p-2 lg:p-5 rounded-xl rounded-t-none shadow-md yellow w-full'>
         
        <li className={category === 'all'? ' px-2 py-1 lg:p-3 cursor-pointer bg-white rounded-full w-28 text-center shadow-sm hover:shadow-lg duration-200': ' px-2 py-1 lg:p-3 hover:bg-white rounded-full w-28 text-center hover:shadow-lg duration-200'} onClick={()=>{changeCategory('all')}} >All</li>
        <li className={category === 'bestseller'? ' px-2 py-1 lg:p-3 cursor-pointer bg-white rounded-full w-28 text-center shadow-sm hover:shadow-lg duration-200': ' px-2 py-1 lg:p-3 cursor-pointer hover:bg-white rounded-full w-28 text-center hover:shadow-lg duration-200'} onClick={()=>{changeCategory('bestseller')}} >Bestsellers</li>
        <li className={category === 'food'? ' px-2 py-1 lg:p-3 cursor-pointer bg-white rounded-full w-28 text-center shadow-sm hover:shadow-lg duration-200': ' px-2 py-1 lg:p-3 cursor-pointer hover:bg-white rounded-full w-28 text-center hover:shadow-lg duration-200'} onClick={()=>{changeCategory('food')}} >Foods</li>
        <li className={category === 'drink'? ' px-2 py-1 lg:p-3 cursor-pointer bg-white rounded-full w-28 text-center shadow-sm hover:shadow-lg duration-200': ' px-2 py-1 lg:p-3 cursor-pointer hover:bg-white rounded-full w-28 text-center hover:shadow-lg duration-200'} onClick={()=>{changeCategory('drink')}} >Drinks</li>
        <li className={category === 'pdf'? ' px-2 py-1 lg:p-3 cursor-pointer bg-white rounded-full w-28 text-center shadow-sm hover:shadow-lg duration-200': ' px-2 py-1 lg:p-3 hover:bg-white rounded-full w-28 text-center hover:shadow-lg duration-200'} onClick={()=>{changeCategory('pdf')}} >PDF</li>
        

      </ul>

      
        { category === 'pdf' ?
          <div className="grid grid-cols-1 gap-10 mt-2 justify-items-center">
            <img src="https://i.ibb.co/bzPtNJC/IMG-20231121-WA0007.jpg" alt="IMG-20231121-WA0007" border="0" />
            <img src="https://i.ibb.co/wNpfkg9/IMG-20231121-WA0008.jpg" alt="IMG-20231121-WA0008" border="0" />
            <img src="https://i.ibb.co/RH9wtNZ/IMG-20231121-WA0006.jpg" alt="IMG-20231121-WA0006" border="0" />
            <img src="https://i.ibb.co/pKLpDGy/IMG-20231121-WA0011.jpg" alt="IMG-20231121-WA0011" border="0" />
            <img src="https://i.ibb.co/x1thCKT/IMG-20231121-WA0009.jpg" alt="IMG-20231121-WA0009" border="0" />
            <img src="https://i.ibb.co/MRwDFkV/IMG-20231121-WA0010.jpg" alt="IMG-20231121-WA0010" border="0" />
          </div>
        :
          category === 'all' ? 
            Data && Data.map((elem, id)=>{
              return(
                <MenuItem key={id} name={elem.name} price={elem.price} price2={elem.price2} extra={elem.extra}/>
                )}) 
          :
          category === 'bestseller' ? 
          Data && Data.map((elem, id)=>{
            if (elem.bst === 1) {
              return(
                <MenuItem key={id} name={elem.name} price={elem.price} price2={elem.price2} extra={elem.extra}/>
                )
            }
            return null;
            }) 
          : 
           
          Data && Data.map((elem, id)=>{
            
            if (elem.category === category) {
              
              return(
                <MenuItem key={id} name={elem.name} price={elem.price} price2={elem.price2} extra={elem.extra}/>
                )
            }
            return null
            
            })
          
        }
        
      

      

    </div>
  )
}


export default Menu