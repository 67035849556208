import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className='bg-[#682900] mt-20 whiteText'>
      <div className="max-w-[1200px] p-7 whiteText text-white mx-auto gap-10 grid md:grid-cols-2 lg:grid-cols-4">
        <img src="https://i.ibb.co/0YH0wnh/293041660-538568821300299-8272804081369724134-n.jpg" className='rounded-full shadow-lg h-36' alt=" of cage cafe" />
        <div>
          <h3 className='text-2xl mb-1 font-semibold'>Pages</h3>
          <Link className='py-1 block opacity-75 ' to={'/'}>Home</Link>
          <Link className='py-1 block opacity-75 ' to={'/menu'}>Menu</Link>
          <Link className='py-1 block opacity-75 ' to={'/gallery'}>Reviews & Gallery</Link>
          <Link className='py-1 block opacity-75 ' to={'/about'}>About & Contact</Link>
        </div>
        <div>
          <h3 className='text-2xl mb-1 font-semibold'>Social Media</h3>
          <a href="http://instagram.com/thecagecafeofficial" className='block py-1 opacity-75' target="_blank" rel="noopener noreferrer">Instagram</a>
          <a href="https://www.facebook.com/Thecagecafe/" className='block py-1 opacity-75' target="_blank" rel="noopener noreferrer">Facebook</a>
          {/* <a href="http://twitter.com" className='block py-1 opacity-75' target="_blank" rel="noopener noreferrer">Twitter</a> */}
          {/* <a href="https://www.google.com/maps/place/The+Cage+Cafe/@28.6609742,77.282973,18z/data=!4m6!3m5!1s0x390cfc9c4966562d:0x6e7d585f0e942edf!8m2!3d28.6600876!4d77.2831934!16s%2Fg%2F11cmrxb76v?entry=ttu" className='block py-1 opacity-75' target="_blank" rel="noopener noreferrer">Location</a> */}
        </div>
        <div>
          <h3 className='text-2xl mb-1 font-semibold'>Visit Us</h3>
          <a href='https://www.google.com/maps/place/The+Cage+Cafe/@28.6609742,77.282973,18z/data=!4m6!3m5!1s0x390cfc9c4966562d:0x6e7d585f0e942edf!8m2!3d28.6600876!4d77.2831934!16s%2Fg%2F11cmrxb76v?entry=ttu' className='opacity-75 inline-block mb-3' target='_blank'><span className='font-semibold'>Address 1</span> : F-2/8A KRISHNA NAGAR Delhi, India 110051</a>
          <a href='https://maps.app.goo.gl/3REjXWVAQPe1phdB7' className='opacity-75 inline-block mb-3' target='_blank'><span className='font-semibold'>Address 2</span> : Plot No-3, Dayanand Vihar, Anand Vihar, Delhi, 110051</a>
          
          <p className='opacity-75'><span className='font-semibold'>Phone</span> : +91 98181 80106</p>
          {/* <p>Phone no</p> */}
        </div>
      </div>
      <hr />
      <p className='text-center opacity-50 py-4'>The Cage Cafe | Copyright © 2023</p>
    </div>
  )
}
